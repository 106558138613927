'use client';

import { variants } from '@frameio-bs/tokens';
import Cookies from 'js-cookie';
import { useCallback, useEffect } from 'react';

/**
 * VariantCookie is a higher order component whose purpose is to modify the
 * variant cookie. It renders no markup.
 *
 * @returns null
 */
const VariantCookie = () => {
  const { Types } = variants;

  // if the variant is largeEnterprise monitor page unload and set the variant
  // to the largeEnterpriseReturningVisitor instead so that the variant will
  // change on next visit
  const onBeforeUnload = useCallback(() => {
    if (Cookies.get('variant') === Types.LargeEnterprise) {
      Cookies.set('variant', Types.LargeEnterpriseReturningVisitor);
    }
  }, []);

  useEffect(() => {
    if (Cookies.get('variant') !== Types.LargeEnterprise) {
      return;
    }
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  return null;
};

export default VariantCookie;
